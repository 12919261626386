import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private socket: WebSocket;

  public maxRetries = 5;
  public currentRetries = 0;

  constructor(private gs: GlobalService) { }

  public connect(): void {
    this.currentRetries++
    this.socket = new WebSocket(environment.WS_URL); // Replace with your WebSocket server URL

    this.socket.addEventListener('open', () => {
      console.log('WebSocket connection established');
      this.gs.setWsConnection(true)
      this.gs.socketConnMade = true
    });

    this.socket.addEventListener('message', (event) => {
      if (event.data.key) {
        return
      }
      this.gs.setLastActiveConn(event.data);
    });

    this.socket.addEventListener('close', () => {
      console.log('WebSocket connection closed');
      this.gs.socketConnMade = false
      this.gs.setWsConnection(false)
      this.gs.reconnectSocketInterval = setInterval(() => {
        if (this.gs.socketConnMade) return clearInterval(this.gs.reconnectSocketInterval)
        if (this.currentRetries >= this.maxRetries) return clearInterval(this.gs.reconnectSocketInterval)
        console.log(`Retrying WSS connection Try: ${this.currentRetries}`)
        this.connect();
      }, 5000)
    });
  }

  send(message: string): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN)
      this.socket.send(message);
  }

  disconnect(): void {
    if (this.socket) {
      this.socket.close();
    }
  }
}
