import { Injectable } from '@angular/core';
import { openDB, DBSchema, IDBPDatabase } from 'idb';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IndexedDBService {

  private db: IDBPDatabase<SurveyDB>;

  private isDbLoadedBroadcast = new BehaviorSubject(false);
  isDbLoaded = this.isDbLoadedBroadcast.asObservable();

  constructor() {
    this.connectDB();
  }

  private async connectDB() {
    this.db = await openDB<SurveyDB>(`${environment.production ? 'production-' : 'staging-'}survey-db`, 37, {
      async upgrade(db) {

        try { db.deleteObjectStore('user-store') } catch (e) { };
        db.createObjectStore('user-store')

        try { db.deleteObjectStore('properties') } catch (e) { };
        db.createObjectStore('properties')

        // try { db.deleteObjectStore('surveys') } catch (e) { };
        // db.createObjectStore('surveys')

        try { db.deleteObjectStore('jobs') } catch (e) { };
        db.createObjectStore('jobs')

        try { db.deleteObjectStore('floor_plans') } catch (e) { };
        db.createObjectStore('floor_plans')

        try { db.deleteObjectStore('floor_plan_attachments') } catch (e) { };
        db.createObjectStore('floor_plan_attachments')

        try { db.deleteObjectStore('doorsets') } catch (e) { };
        db.createObjectStore('doorsets')

        try { db.deleteObjectStore('doorset_questionnaire_attachments') } catch (e) { };
        db.createObjectStore('doorset_questionnaire_attachments')

        try { db.deleteObjectStore('doorleaf') } catch (e) { };
        db.createObjectStore('doorleaf')

        try { db.deleteObjectStore('doorframe') } catch (e) { };
        db.createObjectStore('doorframe')

        try { db.deleteObjectStore('seals') } catch (e) { };
        db.createObjectStore('seals')

        try { db.deleteObjectStore('hardware') } catch (e) { };
        db.createObjectStore('hardware')

        try { db.deleteObjectStore('glaze') } catch (e) { };
        db.createObjectStore('glaze')

        try { db.deleteObjectStore('signage') } catch (e) { };
        db.createObjectStore('signage')

        try { db.deleteObjectStore('grade') } catch (e) { };
        db.createObjectStore('grade')

        try { db.deleteObjectStore('art_numbers') } catch (e) { };
        db.createObjectStore('art_numbers')

        try { db.deleteObjectStore('deleted_entities') } catch (e) { };
        db.createObjectStore('deleted_entities')

        try { db.deleteObjectStore('offline_sync_status') } catch (e) { };
        db.createObjectStore('offline_sync_status')

        try { db.deleteObjectStore('synced_last') } catch (e) { };
        db.createObjectStore('synced_last')
      },
    });
    console.log("IDB Connected");
    localStorage.setItem("db-loaded", "true");
    this.dbLoaded(true)
  }

  dbLoaded(loaded) {
    this.isDbLoadedBroadcast.next(loaded);
  }

  get(store, key) {
    return this.db.get(store, key);
  }

  check(store, key) {
    return this.db.getKey(store, key);
  }

  getAll(store) {
    return this.db.getAllKeys(store);
  }

  add(store, value, key) {
    return this.db.put(store, value, key);
  }

  delete(store, key) {
    return this.db.delete(store, key);
  }

  clearStore(store) {
    return this.db.clear(store);
  }

}

interface SurveyDB extends DBSchema {
  'user-store': {
    key: string;
    value: string;
  };
  'properties': {
    key: string;
    value: string;
  };

  'jobs': {
    key: string;
    value: string;
  }

  'floor_plans': {
    key: string;
    value: string;
  }

  'doorsets': {
    key: string;
    value: string;
  }

  'doorleaf': {
    key: string;
    value: string;
  }

  'doorframe': {
    key: string;
    value: string;
  }

  'seals': {
    key: string;
    value: string;
  }

  'hardware': {
    key: string;
    value: string;
  }

  'glaze': {
    key: string;
    value: string;
  }

  'signage': {
    key: string;
    value: string;
  }

  'grade': {
    key: string;
    value: string;
  }

  'floor_plan_attachments': {
    key: string;
    value: string;
  }

  'doorset_questionnaire_attachments': {
    key: string;
    value: string;
  }

  'art_numbers': {
    key: string;
    value: string;
  }

  'deleted_entities': {
    key: string;
    value: string;
  }

  'offline_sync_status': {
    key: string;
    value: string;
  }

  'synced_last': {
    key: string;
    value: string;
  }
}

/* IndexedDb Schema (All payloads are saved as json stringify)

CakePHP naming conventions, to be noted:
a)- Name table names as plurals, i.e. user => users, survey => surveys, floorplan => floorplans etc
b)- Use underscores after each meaningful word, i.e. survey_doorsets, floor_plans etc
c)- Column names with two or more words has to be connected with underscore i.e. first_name, last_name
d)- Foreign keys need to be named as, survey_id in floorplan table has to be named as survey_id (with _id at the end)
d)- created & modified columns shall be named without "_at" postfix
e)- created_by & modified_by shall be replaced with created_user_id, modified_user_id

ALTER TABLE surveys
ADD FOREIGN KEY (job_id) REFERENCES jobs(id);

Ref: https://book.cakephp.org/4/en/intro/conventions.html#database-conventions

// DELETED SURVEYS TABLE
// 1- surveys

// key => job_id,
// value => survey - (only 1 against 1 job) - single object {}

2- floor_plans

key => floor_uuid
value => floor - (contains job_id in each row/record) - single object {}

3- doorsets

key => doorset_uuid
value => doorset - (contains floor_uuid in each row/record) - single object {}

4- doorleaf

key => doorset_uuid (because only 1 entry of this entity will be saved against each doorset)
value => doorleaf - (contains doorset_uuid in each row/record) - single object {}

5- doorframe

key => doorset_uuid (because only 1 entry of this entity will be saved against each doorset)
value => doorframe - (contains doorset_uuid in each row/record) - single object {}

6- seals

key => doorset_uuid (because only 1 entry of this entity will be saved against each doorset)
value => seals - (contains doorset_uuid in each row/record) - single object {}

7- hardware

key => doorset_uuid (because only 1 entry of this entity will be saved against each doorset)
value => hardware - (contains doorset_uuid in each row/record) - single object {}

8- glaze

key => doorset_uuid (because only 1 entry of this entity will be saved against each doorset)
value => glaze - (contains doorset_uuid in each row/record) - single object {}

9- signage

key => doorset_uuid (because only 1 entry of this entity will be saved against each doorset)
value => signage - (contains doorset_uuid in each row/record) - single object {}

9- grade

key => doorset_uuid (because only 1 entry of this entity will be saved against each doorset)
value => grade - (contains doorset_uuid in each row/record) - single object {}

10- floor_plan_attachments

key => floor_uuid
value => attachments - (floor plan attachments array bound with floor_id)

11- doorset_questionnaire_attachments
key => doorset_uuid
value => attachments array - (where each object will contain a "mod" object containing enums i.e. doorset, doorleaf, doorfram etc)

12- art_numbers
key => art_number
value => art_title

12- deleted_entities
key => entity_id => survey_id, floorplan_id, doorset_id
value => enum => survey, floorplan, doorset
*/
