import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalService } from '../services/global.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private gs: GlobalService, private _router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request to add the authorization header or perform any other necessary modifications

        if (request.url.includes('s3-asset-stream'))
            return next.handle(request)

        const modifiedRequest = request.clone({
            setHeaders: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('ps-auth'))?.jwt}`
            }
        });

        // Handle the modified request
        return next.handle(modifiedRequest).pipe(
            // Catch the response
            catchError((error) => {
                if (error.status === 401 || error.status === 403) {
                    this.gs.presentToast('Your session has expired. Please try login again')
                    this.gs.logout()
                    this._router.navigateByUrl('/');
                }
                return throwError(error); // Re-throw the error to propagate it to the calling code
            })
        );
    }
}
