import { GlobalService } from 'src/app/services/global.service';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { IndexedDBService } from './services/indexed-db.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  dbLoaded = false;

  constructor(private router: Router, private idb: IndexedDBService, private _gs: GlobalService) {
    localStorage.removeItem("db-loaded")
    this.idb.isDbLoaded.subscribe(async loaded => {
      this.dbLoaded = true;
    });

    if (navigator.userAgent.search("Firefox") < 0 && navigator?.serviceWorker) {
      navigator.serviceWorker.ready.then(function (swRegistration: any) {
        return swRegistration.sync.register('surveysSyncOffline');
      });
    }
  }

  ngOnInit() {
    const connectivity = new BroadcastChannel('connectivity');
    connectivity.onmessage = (event) => {
      if (event.data.msg != 200)
        this._gs.presentToast("App might be working in the offline mode. Will get updated data once online");
    }
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if (
          event.id === 1 &&
          event.url === event.urlAfterRedirects
        ) {
          // if (!event.url.includes("add-survey") && event.url != "/jobs")
          //   this.router.navigateByUrl('/jobs')
        }
      })

  }

}
