import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalService } from './services/global.service';
import { IndexedDBService } from './services/indexed-db.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private _gs: GlobalService, private router: Router, private idb: IndexedDBService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (next.queryParams.admin)
      localStorage.setItem('ps-auth', JSON.stringify({ jwt: next.queryParams.admin, isAdmin: true }))
    return new Promise(async (resolve, reject) => {
      const isAuth = await this._gs.isAuthenticated();
      if (!isAuth) {
        try {
          this.idb.clearStore("user-store");
        } catch (e) { }
        this.router.navigateByUrl('/');
        resolve(false);
      } else
        resolve(true);
    });
  }

}
