import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  login({ username, password }) {
    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.http.post<any>(`${environment.API_BASE_URL}/auth/login`, { email: username, password }, httpOptions);
  }

  getS3AssetStream(s3Key): Observable<any> {
    return this.http.get<any>(`${environment.API_BASE_URL}/employee/s3-asset-stream?s3Key=${s3Key}`, {
      headers: new HttpHeaders({
        // Accept: 'application/octet-stream',
        Accept: 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('ps-auth'))?.jwt}`
      }),
      responseType: 'json',
      reportProgress: true,
      observe: 'events'
    });
  }

  getS3Asset(s3Key) {
    return this.http.get<any>(`${environment.API_BASE_URL}/employee/s3-asset?s3Key=${s3Key}`).toPromise();
  }

  getProperties() {
    return this.http.get<any>(`${environment.API_BASE_URL}/employee/property`).toPromise();
  }

  getArtNumbers() {
    return this.http.get<any>(`${environment.API_BASE_URL}/superadmin/artNumbers`).toPromise();
  }

  getJobForAdmin(jobId) {
    return this.http.get<any>(`${environment.API_BASE_URL}/superadmin/job?id=${jobId}`, {
      headers: new HttpHeaders({
        // Accept: 'application/octet-stream',
        Accept: 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('ps-auth'))?.jwt}`
      })
    }).toPromise();
  }

  getJobs(jobIds = null) {
    return this.http.get<any>(`${environment.API_BASE_URL}/employee/jobs${jobIds ? `?jobIds=${jobIds}` : ``}`).toPromise();
  }

  saveJob(job: any) {
    return this.http.post<any>(`${environment.API_BASE_URL}/employee/jobs`, job).toPromise();
  }

  saveJobDataInChunks(data) {
    return this.http.post<any>(`${environment.API_BASE_URL}/employee/upload-chunks`, data).toPromise();
  }

}
