import { HttpClient } from '@angular/common/http';
import { Doorleaf } from './../models/Doorleaf';
import { Doorset } from 'src/app/models/Doorset';
import { ArtNumber } from './../models/ArtNumber';
import { Floorplan } from './../models/Floorplan';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController, AlertController } from '@ionic/angular';
import { Subject, Subscription, Observable } from 'rxjs';
import { IndexedDBService } from './indexed-db.service';

import { environment } from 'src/environments/environment';

import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  appVersion = 'V24';

  apiInitObject = {
    body: {},
    headers: {
      authorization: null
    },
    response: true,
    queryStringParameters: {
      // name: 'param'
    }
  };

  artNumbersForPipe = [];

  offlineDataInterval = null;

  dbLoaded = true;

  loadedTabs = {
    'doorset': false,
    'doorleaf': false,
    'doorframe': false,
    'seals': false,
    'hardware': false,
    'glaze': false,
    'signage': false,
    'grade': false
  };

  saveQuestionnaireEvent = new Subject<any>();
  connectionCheckEvent = new Subject<any>();
  slideActivated = new Subject<any>();
  activeModuleSaved = new Subject<any>();

  isWsConnected = new Subject<any>();

  subscribedEvents = [];

  subsEvents: Subscription[] = [];

  private emailValidatorPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  private alphabetic = /^[a-zA-Z\s]*$/;
  private numeric = /^[0-9]*$/;
  private float = /^[0-9.]*$/;
  private numericAndSpace = /^[0-9\s]*$/;
  public lastActiveConn = null;
  public reconnectSocketInterval = null;
  public socketConnMade = false;

  constructor(public toastController: ToastController, private alertController: AlertController, private idb: IndexedDBService, private router: Router, private http: HttpClient) {
    this.offlineDataInterval = setInterval(() => {
      if (localStorage.getItem("db-loaded") && localStorage.getItem("db-loaded") == "true") {
        this.dbLoaded = true;
        localStorage.removeItem("db-loaded");
        clearInterval(this.offlineDataInterval);
      }
    }, 500)
  }

  updateLoadedTabs(tab, status = true) {
    this.loadedTabs[tab] = status
  }

  resetLoadedTabs() {
    this.loadedTabs = {
      'doorset': false,
      'doorleaf': false,
      'doorframe': false,
      'seals': false,
      'hardware': false,
      'glaze': false,
      'signage': false,
      'grade': false
    };
  }

  getUniqueId() {
    return uuidv4();
  }

  connectionCheck(isLive) {
    this.connectionCheckEvent.next(isLive);
  }

  saveQuestionnaire(questionnaire) {
    this.saveQuestionnaireEvent.next(questionnaire);
  }

  activateSlide(slide) {
    this.slideActivated.next(slide);
  }

  async getSession() {
    return await this.idb.get("user-store", "session_id");
  }

  getContentSizeMb(subject) {
    let size = new TextEncoder().encode(JSON.stringify(subject)).length
    let kiloBytes = size / 1024;
    return (kiloBytes / 1024)
  }

  async presentToast(msg, duration = 2000) {
    const toast = await this.toastController.create({
      message: msg,
      duration: duration
    });
    toast.present();
  }

  async isAuthenticated() {
    return localStorage.getItem('ps-auth') ? true : false;
  }

  logout() {
    this.idb.clearStore("user-store").then(x => {
      localStorage.removeItem('ps-auth')
      this.router.navigateByUrl("/");
    });
  }

  setLastActiveConn(timestamp) {
    this.lastActiveConn = timestamp;
  }

  async presentAlertWithButtonsInput(header, msg, maxLimit, yesHandler, noHandler) {
    const alert = await this.alertController.create({
      header: header,
      message: msg,
      inputs: [
        {
          name: 'pdf-page-num',
          type: 'number',
          placeholder: 'PDF Page#',
          attributes: {
            id: 'pdf-page-num-input',
            max: maxLimit
          }
        }
      ],
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'noToastBtn',
          handler: noHandler
        }, {
          text: 'Yes',
          cssClass: 'yesToastBtn',
          handler: yesHandler
        }
      ]
    });
    await alert.present();
    (document.getElementsByClassName("yesToastBtn")[0] as HTMLButtonElement).style.fontWeight = "bold";
    (document.getElementsByClassName("noToastBtn")[0] as HTMLButtonElement).style.fontWeight = "bold";
  }

  async presentAlertWithButtons(header, msg, yesColor, noColor, handler) {
    const alert = await this.alertController.create({
      header: header,
      message: msg,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'noToastBtn'
        }, {
          text: 'Yes',
          cssClass: 'yesToastBtn',
          handler: handler
        }
      ]
    });
    await alert.present();
    (document.getElementsByClassName("yesToastBtn")[0] as HTMLButtonElement).style.color = yesColor;
    (document.getElementsByClassName("yesToastBtn")[0] as HTMLButtonElement).style.fontWeight = "bold";

    (document.getElementsByClassName("noToastBtn")[0] as HTMLButtonElement).style.color = noColor;
    (document.getElementsByClassName("noToastBtn")[0] as HTMLButtonElement).style.fontWeight = "bold";
  }

  async presentAlertInSurveyWithButtons(header, msg, yesColor, noColor, handler, btnTxt = 'Submit') {
    const alert = await this.alertController.create({
      header: header,
      message: msg,
      cssClass: "scaledAlert",
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'noToastBtn'
        }, {
          text: btnTxt,
          cssClass: 'yesToastBtn',
          handler: handler
        }
      ]
    });
    await alert.present();
    (document.getElementsByClassName("yesToastBtn")[0] as HTMLButtonElement).style.color = yesColor;
    (document.getElementsByClassName("yesToastBtn")[0] as HTMLButtonElement).style.fontWeight = "bold";

    (document.getElementsByClassName("noToastBtn")[0] as HTMLButtonElement).style.color = noColor;
    (document.getElementsByClassName("noToastBtn")[0] as HTMLButtonElement).style.fontWeight = "bold";
  }

  async presentToastWithOptions(header, msg, handler) {
    const toast = await this.toastController.create({
      header: header,
      message: msg,
      position: 'top',
      buttons: [
        {
          side: 'end',
          // icon: 'star',
          cssClass: 'yesToastBtn',
          text: 'Yes',
          handler: handler
        }, {
          side: 'end',
          cssClass: 'noToastBtn',
          text: 'No',
          role: 'cancel'
        }
      ]
    });
    await toast.present();

    const { role } = await toast.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  setWsConnection(isConnected) {
    this.isWsConnected.next(isConnected);
  }

  saveModule(mod) {
    this.activeModuleSaved.next(mod);
  }

  getFloorsForJob(jobId): any {
    return new Promise(async (resolve, reject) => {
      // Get all floors
      const allFloorIds = await this.idb.getAll("floor_plans");
      const floorplans: Floorplan[] = [];
      for (let floorId of allFloorIds) {
        //Filter only those floors which has jobId = current Survey uuid
        const floorplan: Floorplan = JSON.parse(await this.idb.get("floor_plans", floorId));
        if (
          floorplan.jobId == jobId
          ||
          floorplan.job_id == jobId //This statement is useless in future builds. Remove it after testing
        ) {
          floorplans.push(floorplan);
          floorplan.attachment_base64 = JSON.parse(await this.idb.get("floor_plan_attachments", floorId))
        }
      }
      resolve(floorplans);
    })
  }

  async deleteJobAndRelevantDataLocally(invalidJobs) {
    for (const ij of invalidJobs) {
      const existingJob = await this.idb.get("jobs", ij);
      if (!existingJob)
        continue;
      const allFplans: Floorplan[] = await this.getFloorsForJob(ij);
      const fpDoorsets = await this.getDoorsetsForTheseFloorplans(allFplans.map(x => x.uuid))
      for (const dset of fpDoorsets) {
        await this.deleteDoorsetData(dset.uuid, true)
      }
      for (const fplan of allFplans) {
        await this.idb.delete("floor_plans", fplan.uuid);
        await this.idb.delete("floor_plan_attachments", fplan.uuid);
      }
      await this.idb.delete("jobs", ij);
    }
  }

  getDoorsetsForTheseFloorplans(fplanUuids): any {
    return new Promise(async (resolve, reject) => {
      // Get all floors
      const allDsetsIds = await this.idb.getAll("doorsets");
      const doorsets: Doorset[] = [];
      for (let dsetId of allDsetsIds) {
        const doorset: Doorset = JSON.parse(await this.idb.get("doorsets", dsetId));
        if (fplanUuids.find(fp => fp == doorset.floor_uuid)) {
          doorsets.push(doorset);
        }
      }
      resolve(doorsets);
    })
  }

  getQuestionnaireForThisDset(allDsetUuids, que): any {
    return new Promise(async (resolve, reject) => {
      // Get Questionnaire
      const allDleafIds = await this.idb.getAll(que);
      const questionnaire = [];

      for (let dleafId of allDleafIds) {
        if (allDsetUuids.includes(dleafId)) {
          questionnaire.push(JSON.parse(await this.idb.get(que, dleafId)));
        }
      }
      resolve([].concat.apply([], questionnaire));
    })
  }

  async getArtNumbers(): Promise<ArtNumber[]> {
    return new Promise(async (res, rej) => {
      const artNums: ArtNumber[] = [];
      const allArtKeys = await this.idb.getAll("art_numbers");
      for (let ak = 0; ak < allArtKeys.length; ak++) {
        let artMeta = JSON.parse(await this.idb.get('art_numbers', allArtKeys[ak]));
        artNums.push({
          id: allArtKeys[ak].toString(),
          art_number: allArtKeys[ak].toString(),
          art_title: artMeta.title,
          art_description: artMeta.description
        });
      }
      this.artNumbersForPipe = artNums;
      res(artNums);
    });
  }

  focusOnArtNumberAccordion(id) {
    document.getElementById(`artNumberAccordion${id}`).scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      (document.getElementById(`artNumberAccordion${id}`) as HTMLElement).click();
    }, 500);
  }

  validate(subject, elId, el = "card"): Promise<boolean> {
    return new Promise((res, rej) => {
      if ((subject == null || subject == "" || (Array.isArray(subject) && !subject.length))) {
        this.cardElementWarning(elId, "add", el)
        res(true)
      } else {
        this.cardElementWarning(elId, "remove", el)
        res(false)
      }
    })
  }

  cardElementWarning(cardId, op = "add", el = "card") {
    if (!cardId) {
      const els = Array.prototype.slice.call(document.getElementsByClassName(`warning-input-card`));
      for (let i = 0; i < els.length; i++) {
        (document.getElementById(els[i].getAttribute("id")) as HTMLElement).classList.remove(`warning-input-card`);
      }
      const elsInp = Array.prototype.slice.call(document.getElementsByClassName(`warning-input`));
      for (let i = 0; i < elsInp.length; i++) {
        (document.getElementById(elsInp[i].getAttribute("id")) as HTMLElement).classList.remove(`warning-input`);
      }
    } else {
      if (document.getElementById(cardId)) {
        if (op == "remove")
          el != "select" ? (document.getElementById(cardId) as HTMLElement).classList.remove(`warning-input${el == 'card' ? '-card' : ''}`) : (document.getElementById(cardId).parentNode as HTMLElement).classList.remove(`warning-input-card`)
        else {
          if (el != "select")
            (document.getElementById(cardId) as HTMLElement).classList.add(`warning-input${el == 'card' ? '-card' : ''}`)
          else {
            (document.getElementById(cardId).parentNode as HTMLElement).classList.add(`warning-input-card`)
          }
        }
      }
    }
  }

  deleteDoorsetData(doorsetUuid, deleteDoorsetAlso = false) {
    return new Promise(async (res, rej) => {
      await this.idb.delete("glaze", doorsetUuid);
      await this.idb.delete("signage", doorsetUuid);
      await this.idb.delete("grade", doorsetUuid);
      await this.idb.delete("hardware", doorsetUuid);
      await this.idb.delete("seals", doorsetUuid);
      await this.idb.delete("doorframe", doorsetUuid);
      await this.idb.delete("doorleaf", doorsetUuid);
      await this.idb.delete("doorset_questionnaire_attachments", doorsetUuid);
      if (deleteDoorsetAlso)
        await this.idb.delete('doorsets', doorsetUuid)
      res(true)
    })
  }

  checkInternetConnection() {
    return new Promise(async (resolve, reject) => {
      fetch(`${environment.API_BASE_URL}/account/account/checkConnection?v=${new Date().getTime()}`, {
        method: "GET"
      }).then(response => {
        resolve(response.status)
      }).catch(exc => {
        reject(exc)
      });
    });
  }

  eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;';
  }

  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  async updateDoorsetAttachmentsWithDoorsetId() {
    return new Promise(async (resolve, reject) => {
      const allAttachmentsKeys = await this.idb.getAll("doorset_questionnaire_attachments");
      for (let attK = 0; attK < allAttachmentsKeys.length; attK++) {
        const attachments = JSON.parse(await this.idb.get("doorset_questionnaire_attachments", allAttachmentsKeys[attK]));
        for (let i = 0; i < attachments.filter(att => !att.doorset_uuid).length; i++) {
          attachments[i].doorset_uuid = allAttachmentsKeys[attK];
          await this.idb.add("doorset_questionnaire_attachments", JSON.stringify(attachments), allAttachmentsKeys[attK]);
        }
      }

      resolve(true);
    });
  }

  async downloadBlob(blob, name = 'file.json') {
    return new Promise((resolve, reject) => {
      // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
      const blobUrl = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");

      // Set link's href to point to the Blob URL
      link.href = blobUrl;
      link.download = name;

      // Append link to the body
      document.body.appendChild(link);

      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );

      // Remove link from body
      document.body.removeChild(link);

      resolve(true);
    });
  }

  getJSON(filePath): Observable<any> {
    return this.http.get(filePath);
  }

  /**
   * Returns a hash code for a string.
   * (Compatible to Java's String.hashCode())
   *
   * The hash code for a string object is computed as
   *     s[0]*31^(n-1) + s[1]*31^(n-2) + ... + s[n-1]
   * using number arithmetic, where s[i] is the i th character
   * of the given string, n is the length of the string,
   * and ^ indicates exponentiation.
   * (The hash value of the empty string is zero.)
   *
   * @param {string} s a string
   * @return {number} a hash code value for the given string.
   */
  hashCode = function (s) {
    let h = 0, l = s.length, i = 0;
    if (l > 0)
      while (i < l)
        h = (h << 5) - h + s.charCodeAt(i++) | 0;
    return h;
  }

  loadBackupDataFromJsonFile(filepath) {

    const hash = this.hashCode(filepath);

    if (this.getCookie(`importing-db-${hash}`))
      return console.warn(`Not importing ${filepath} again.`);
    this.setCookie(`importing-db-${hash}`, true, 1);
    console.log(`Importing: ${filepath}`);

    return new Promise((resolve, reject) => {
      this.getJSON(filepath).subscribe(async x => {
        for (let i = 0; i < x.data.data.length; i++) {
          const tbl = x.data.data[i].tableName;
          for (let j = 0; j < x.data.data[i].rows.length; j++) {
            const row = x.data.data[i].rows[j];

            if (tbl == "doorset_questionnaire_attachments") {
              const attachmentsData = [].concat.apply([], JSON.parse(row[1]));

              attachmentsData.map(am => {
                if (!am.doorset_uuid)
                  am.doorset_uuid = row[0];

                return am;
              })

              row[1] = attachmentsData.filter(x => x.doorset_uuid == row[0]);
              if (row[1].length)
                row[1] = JSON.stringify(row[1]);
              else {
              }
            }

            if (tbl == "surveys") {
              row[1] = JSON.parse(row[1]);
              row[1]["completed"] = true;

              row[1].synced == 0
              row[1] = JSON.stringify(row[1]);
            }

            if (tbl == "surveys") {
              row[1] = JSON.parse(row[1]);
              row[1]["completed"] = true;
              row[1].synced == 0
              row[1] = JSON.stringify(row[1]);
            }

            await this.idb.add(tbl, row[1], row[0]);
          }
          console.log(`Saving Table: ${tbl}`)
        }
        console.log(`Import complete: ${filepath}`)
        // alert(`All data imported to IndexDb`)
        resolve(true);
      })
    })
  }

}
